.footer {
  background-color: #333333;
  color: white;
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.request-callback-form1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;

}

.request-callback-form1 h1 {
  color: #414042 !important;
  /* color: white; */
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.188rem;
  font-weight: 700;
  line-height: 120%;
  margin: 0px auto 30px !important;

}


/* .request-callback-form1 {
  margin: 0;
} */
.request-callback-form1 {
  margin-top: -12%;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-map {
  border-radius: 0px 40px 0px 0px;
  overflow: hidden;
  margin-right: 40px;
}

.footer-map iframe {
  /* width: 100%;  */
  width: 570px;
  height: 298px;
}

.footer-contact {
  width: 45%;
}

.footer-contact h2 {
  margin-bottom: 20px;
  color: #fff;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  text-transform: uppercase;
}

.footer-contact-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* Reduced gap */
  margin-bottom: 20px;
}

.contact-info-group {
  flex: 1;
  min-width: 200px;
}

.contact-info-group p {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 174%;
}

.contact-info-group a {
  color: #fff;
  text-decoration: none ;
}

.contact-info-title {
  color: var(--text-color);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 174%;
  margin-bottom: 5px;
}

.contact-info-detail {
  margin-bottom: 10px;
  color: #cccccc;
}

.footer-contact-additional {
  display: flex;
  flex-direction: column;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 20px;
}

.footer-bottom-left {
  display: flex;
  align-items: end;
  gap: 57px;
}

.back-btn-div {
  display: flex;
  justify-content: center;
}

.back-to-top-footer {
  bottom: 20px;
  right: 20px;
  background: rgba(217, 217, 217, 0.30);
  border: none;
  padding: 10px 35px;
  width: 176px;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: background-color 0.3s ease;
}

.back-to-top:hover {
  background-color: #d19936;
}

.footer-logo {
  width: 140px;
  height: auto;
  margin-right: 20px;
}

.footer-copyright {
  color: #fff;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 174%;
  /* 22.62px */
}

.footer-social-icons {
  display: flex;
  width: 45%;
  gap: 15px;
}

.footer-social-icons img {
  height: 24px;
}

/* Mobile Styles */

@media (max-width: 1000px) {
  .footer-contact h2 {
    font-size: 40px;
  }

  .contact-info-group p {
    font-size: 14px;
  }

  .contact-info-group {
    min-width: 0;
  }

  .footer-copyright {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 40px;
  }

  .contact-info-title {
    font-size: 16px;
  }

  .contact-info-group p {
    font-size: 13px;
  }

  .footer-top {
    flex-direction: column;
    gap: 20px;
  }

  .footer-map {
    width: 100%;
    margin-right: 20px;
  }

  .footer-map iframe {
    width: 100%;
    height: 298px;
  }

  .footer-contact {
    width: 100%;
  }

  .footer-contact h2 {
    font-size: 36px;
  }

  .contact-info-group {
    min-width: auto;
  }

  .footer-contact-additional {
    margin-top: 20px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer-bottom-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .footer-logo {
    width: 100px;
  }

  .footer-copyright {
    font-size: 12px;
    text-align: center;
  }

  .footer-social-icons {
    justify-content: center;
    gap: 10px;
  }

  .footer-social-icons img {
    height: 20px;
  }

  .request-callback-form1 {
    margin-top: -50%;
  }
}

@media (max-width: 480px) {
  .request-callback-form1 h1 {
    font-size: 2rem;
  }
}

@media (max-width: 400px) {

  .footer-contact h2 {
    font-size: 28px;
  }

  .contact-info-group p {
    color: #fff;
    font-family: Montserrat;
    font-size: 10px;
  }

  .footer-copyright {
    font-size: 10px;
  }

}