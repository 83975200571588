/* Desktop */
.MeetJSBExperts-desktop-main {
  padding: 40px 0;
  background-color: #fff;
  position: relative;
}

.MeetJSBExperts-desktop-title {
  font-size: 3.75rem;
  line-height: 120%;
  display: flex;
  font-family: Montserrat;
  color: #414042;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 60px;
}

.MeetJSBExperts-desktop-container {
  display: flex;
  flex-wrap: wrap;
  width: 98%;
  background-color: var(--nav-bg-color);
  position: relative;
  border-radius: 0px 40px 0px 0px;
  /* border-radius: 32px; */
}

.MeetJSBExperts-desktop-description {
  flex: 1 1 40%;
  /* padding: 50px 80px; */
  padding: 50px;
}

.MeetJSBExperts-desktop-description-yellow-text {
  color: var(--text-color);
  /* font-size: 1.125rem; */
  font-size: 1rem;
  line-height: 150%;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: "Montserrat";
}

.MeetJSBExperts-desktop-description-white-text {
  color: #fff;
  /* font-size: 0.875rem; */
  font-size: 1rem;
  line-height: 150%;
  /* line-height: 180%; */
  font-weight: 600;
  font-family: "Montserrat";
}

.MeetJSBExperts-desktop-image-container {
  flex: 1 1 40%;
  position: relative;
  /* margin-right: -2%; */
}

.MeetJSBExperts-desktop-building-image {
  width: 100% !important;
  margin-top: -47.7%;
}

.MeetJSBExperts-desktop-leadership-message {
  padding: 10px 0px;
  color: #fff;
  text-align: left;
  /* margin-bottom: 20px; */
  margin-bottom: 10%;
}

.MeetJSBExperts-desktop-leadership-message-text {
  /* line-height: 120%; */
  line-height: 150%;
  font-weight: 600;
  /* font-size: 1.3rem; */
  font-size: 1rem;
  font-family: Montserrat;
}

.MeetJSBExperts-read-more-button {
  color: #fff;
  text-decoration: underline;
  font-family: "Montserrat" !important;
  padding: 20px 0px;
  border: none;
  font-size: 1rem !important;
  cursor: pointer;
  margin-top: 20px;
}

.MeetJSBExperts-desktop-mission-vision-container {
  /* height: 320px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--div-bg-color);
  padding: 20px 40px 30px;
  position: relative;
  z-index: 100;
  margin-top: -6%;
  width: 98%;
  right: -2%;
  border-radius: 200px 0 0 200px;
}

.MeetJSBExperts-desktop-mission-vision-card {
  color: #fff;
  padding: 20px 30px;
  border-radius: 15px;
  text-align: center;
  margin: 0 10px;
  flex: 1;
}

.MeetJSBExperts-desktop-card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.MeetJSBExperts-desktop-card-description {
  font-size: 1rem;
}

.MeetJSBExperts-desktop-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

/* Responsive styles */

@media (min-width: 1600px) and (max-width: 1800px) {
  .MeetJSBExperts-desktop-container {
    height: 1000px;
  }
}

@media (min-width: 1800px) and (max-width: 2560px) {
  .MeetJSBExperts-desktop-container {
    height: 1650px;
  }

  .MeetJSBExperts-desktop-description-yellow-text {
    font-size: 1.5rem;
  }

  .MeetJSBExperts-desktop-description-white-text {
    font-size: 1.5rem;
  }

  .MeetJSBExperts-desktop-title {
    font-size: 70px;
  }


  .MeetJSBExperts-desktop-leadership-message-text {
    font-size: 1.5rem;
    margin-top: 10px;
  }

  .MeetJSBExperts-desktop-mission-vision-container {
    margin-top: -5%;
  }

  .MeetJSBExperts-desktop-card-title {
    font-size: 2rem;
  }

  /* .MeetJSBExperts-desktop-card-description {
    font-size: 1.5rem;
  } */
}

@media (min-width: 769px) and (max-width: 1024px) {
  .MeetJSBExperts-desktop-description {
    padding: 30px 80px;
  }

  .MeetJSBExperts-desktop-description-yellow-text {
    font-size: 0.9rem;
  }

  .MeetJSBExperts-desktop-description-white-text {
    font-size: 0.9rem;
  }

  .MeetJSBExperts-desktop-title {
    font-size: 40px;
  }

  .MeetJSBExperts-desktop-leadership-message {
    font-size: 12px;
  }

  .MeetJSBExperts-desktop-card-title {
    font-size: 1rem;
  }

  .MeetJSBExperts-desktop-card-description {
    font-size: 0.6rem;
  }
}

/* Mobile View Css */

.MeetJSBExperts-parent {
  text-align: center;
  position: relative;
}

.MeetJSBExperts-mobile-title {
  color: #414042;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.188rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 80px;
  margin-bottom: 20px;
}

.MeetJSBExperts-mobile-building-image {
  width: 95%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  z-index: 2;
  left: -4%;
}

.MeetJSBExperts-subparent {
  background-color: #414042;
  padding: 60px 40px;
  margin-top: -50px;
  position: relative;
  /* height: 1621px; */
  height: auto;
  z-index: 1;
}

.MeetJSBExperts-mobile-description-container {
  padding: 15px;
  background-color: #414042;
  color: #d9a11a;
  border-radius: 5px;
}

.MeetJSBExperts-mobile-description-yellow-text {
  color: #dca52e;
  font-family: Montserrat;
  /* font-size: 1.125rem; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 20px;
}

.MeetJSBExperts-mobile-description-white-text {
  color: #fff;
  font-family: Montserrat;
  /* font-size: 0.875rem; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  /* line-height: 180%; */
  line-height: 150%;
  margin-bottom: 10px;
}

.MeetJSBExperts-mobile-leadership-message {
  /* font-size: 1rem; */
  font-size: 1rem;
  /* line-height: 180%; */
  line-height: 150%;
  font-weight: 600;
  font-family: Montserrat;
  color: #fff;
  text-align: left;
  margin-bottom: 10%;
}

.MeetJSBExperts-mobile-mission-vision-container {
  background-color: var(--div-bg-color);
  color: white;
  position: relative;
  /* height: 459px; */
  padding: 40px 45px;
  width: 97%;
  right: -3%;
  z-index: 99999999 !important;
  margin-top: -40%;
  border-radius: 100px 0px 0px 100px;
}

.MeetJSBExperts-mobile-mission-vision-card {
  text-align: center;
  padding: 15px 10px;
  margin-bottom: 15px;
}

.MeetJSBExperts-mobile-icon {
  width: 49px;
  height: 49px;
  margin-bottom: 10px;
}

.MeetJSBExperts-mobile-card-title {
  text-align: center;
  font-family: Montserrat;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 5px;
}

.MeetJSBExperts-mobile-card-description {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
}

.MeetJSBExperts-mobile-read-more-btn {
  color: #fff;
  text-decoration: underline;
  font-family: "Montserrat" !important;
  padding: 20px 0px;
  border: none;
  font-size: 1rem !important;
  cursor: pointer;
  margin-top: 20px;
}


@media (max-width: 768px) {
  .MeetJSBExperts-mobile-mission-vision-container {
    margin-top: -18%;
  }
}

@media (max-width: 600px) {
  .MeetJSBExperts-mobile-mission-vision-container {
    margin-top: -24%;
  }
}

@media (max-width: 500px) {
  .MeetJSBExperts-mobile-mission-vision-container {
    margin-top: -27%;
  }
}

@media (max-width: 400px) {
  .MeetJSBExperts-mobile-mission-vision-container {
    margin-top: -37%;
  }
}