.floor-plans {
  text-align: center;
  padding: 80px;
  background-color: #d9d9d9;
}

.resources-title {
  font-size: 2.188rem;
  line-height: 120%;
  text-transform: uppercase;
  display: inline-block;
  font-family: Montserrat;
  color: #414042;
  margin-top: 103px;
}

.floor-plans-title {
  font-size: 1.875rem;
  line-height: 120%;
  font-family: Montserrat;
  color: #414042;
  margin: 40px 0px;
}

.floor-plan-slide {
  position: relative;
  overflow: hidden;
}

.floor-plan-image {
  width: 100%;
  height: 500px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}


/* .floor-plan-image {
  width: 373px; 
  height: 427px; 
  object-fit: cover;
  cursor: pointer; 
  transition: transform 0.3s ease-in-out;
} */


.floor-plan-text {
  position: absolute;
  bottom: 0;
  /* width: 100%; */
  width: 385px;
  object-fit: cover;

  background: rgba(0, 0, 0, 0.6);
  color: white;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.floor-plan-slide:hover .floor-plan-image {
  transform: scale(1.05);
}

.floor-plan-slide:hover .floor-plan-text {
  opacity: 1;
}

.download-brochure {
  display: inline-block;
  margin-top: 20px;
  font-size: 1.563rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: #414042;
  text-decoration: underline;
  padding: 10px 20px;
  border-radius: 20px;
}

.download-brochure:hover {}

.slick-prev,
.slick-next {
  z-index: 1;
}

.prev-slide-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  left: -20px;
}

.next-slide-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  right: -20px;
}

.prev-slide-arrow img,
.next-slide-arrow img {
  width: 50px;
  height: 50px;
}

.slick-slide {
  padding: 0 10px;
}


/* Project location */

/* Project location */
.floor-plans-project-location-title {
  color: #414042;
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.188rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 70px;
  margin-bottom: 60px;
}



/* RERA Details */
/* .rera-details-container{
  margin-bottom: 70%;
} */
.rera-details-title {
  color: #414042;
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.188rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 60px;
  margin-bottom: 50px;
}

.rera-details-container {
  text-align: center;
  color: #414042;
  margin-bottom: 30px;
}


.rera-details-container p {
  color: #414042;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
}

.rera-details-container a {
  text-decoration: underline;
  color: #414042;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
  letter-spacing: -0.42px;
}

.rera-details-container a:hover {
  text-decoration: underline;
}


.qr-codes {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.qr-code-image {
  width: 150px;
  height: auto;
  /* border: 1px solid #ddd; */
  /* border-radius: 5px; */
}


.request-callback-form {
  display: flex;
  flex-direction: column; 
  align-items: center;
  margin: 0;

}

.request-callback-form h1 {
  color: #414042 !important;
  /* color: white; */
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.188rem;
  font-weight: 700;
  line-height: 120%;
  margin: 0px auto 30px !important;

}

/* Callback form Css */


.enquiry-form-title-veda {
  color: #414042 !important;
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.188rem;
  font-weight: 700;
  line-height: 120%;
  margin: 50px auto 20px;
}

.request-callback-form {
  background-color: #d19936;
  padding: 40px;
  display: flex;
  position: relative;
  margin: 0px 30px;
  justify-content: center;
  align-items: center;
  margin-top: -5%;
  z-index: 9999999 !important;
}

.callback-form-container {
  display: flex;
  align-items: center;
}

.callback-logo img {
  width: 80px;
  height: 80px;
}

.callback-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  gap: 10px;
}

.callback-form input,
.callback-form select {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
}

.callback-form select {
  flex-basis: 100%;
}

.callback-form button {
  padding: 10px 20px;
  background-color: #414042;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.callback-form button:hover {
  background-color: #333;
}

.callback-form input[type="text"]:first-child,
.callback-form select {
  flex: 1;
}

.callback-form input[type="text"]:nth-child(3),
.callback-form input[type="email"],
.callback-form button {
  flex: 1;
}

.callback-form select {
  flex-basis: calc(50% - 5px);
}

.callback-form input[type="text"]:first-child {
  flex-basis: calc(50% - 5px);
}




/* Modal Overlay */
.floor-plans-veda1-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999 !important;
}

/* Modal Content */
.floor-plans-veda1-modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  overflow: hidden; /* Ensures content doesn't overflow */
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Centers the content vertically */
}

/* Image styling */
.floor-plans-veda1-modal-image {
  max-width: 100%;
  max-height: 100vh; /* Prevent image from being too tall for the screen */
  object-fit: contain; /* Ensures the image scales properly */
  cursor: pointer;
  transition: transform 0.25s ease-in-out; /* Smooth transition for zooming */
}

/* Zoomed image */
.floor-plans-veda1-modal-image.zoomed {
  transform: scale(2); /* Adjust scale factor as necessary */
  cursor: grabbing; /* Change cursor when zoomed */
}

/* Close button */
.floor-plans-veda1-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

/* Allow scrolling on zoomed image */
.floor-plans-veda1-modal-content.zoomed-image-wrapper {
  overflow: auto; /* Enable scrolling */
  max-width: 90vw;  /* Set a reasonable max-width */
  max-height: 90vh; /* Set a reasonable max-height */
  padding: 0; /* Remove padding around the image when zoomed */
}

/* Add to FloorPlans.css */

.floor-plans-veda1-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  overflow: hidden; /* Prevent background from scrolling */
}

.floor-plans-veda1-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.floor-plans-veda1-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  z-index: 1000;
}

.floor-plans-veda1-modal-image {
  max-width: none;
  /* max-height: none; */
  cursor: zoom-out;
  transition: transform 0.3s ease;
}

.floor-plans-veda1-modal-image.zoomed {
  transform: scale(2); /* Adjust the scale as needed */
  transform-origin: center;
}

/* @media (max-width: 768px) {
  .floor-plans {
    padding: 40px;
  }

  .resources-title {
    margin-top: 30px;
    font-size: 2.188rem;
  }
  .floor-plans-title {
    font-size: 1.563rem;
    margin: 30px;
  }
  .slick-slide {
    padding: 0;
  }

  .prev-slide-arrow img,
  .next-slide-arrow img {
    width: 40px;
    height: 40px;
  }

  .floor-plan-slide {
    margin-bottom: 20px;
  }
} */



@media (max-width: 1024px) {
  iframe {
    width: 600px;
    height: 298px;
  }
}

@media (min-width: 1025px) {
  iframe {
    width: 1161px;
    height: 463px;
    border-radius: 0px 40px 0px 0px;
  }
}


@media (max-width: 768px) {

  /* Adjust the overall padding */
  .floor-plans {
    padding: 40px;
  }

  .request-callback-form {
    margin-top: -30%;
  }

  /* Adjust the titles */
  .resources-title {
    margin-top: 30px;
    font-size: 2.188rem;
  }

  .floor-plans-title {
    font-size: 1.563rem;
    margin: 30px;
  }

  /* Adjust the Slick slider elements */
  .slick-slide {
    padding: 0;
  }

  .prev-slide-arrow img,
  .next-slide-arrow img {
    width: 40px;
    height: 40px;
  }

  .floor-plan-slide {
    margin-bottom: 20px;
  }

  .callback-form-container {
    flex-direction: column;
    align-items: center;
  }

  /* Logo adjustments */
  .callback-logo {
    margin-bottom: 20px;
  }

  .callback-logo img {
    width: 60px;
    height: 60px;
  }

  /* Form adjustments */
  .callback-form {
    margin-left: 0;
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .callback-form input,
  .callback-form select,
  .callback-form button {
    width: 100%;
    flex: none;
  }

  .qr-codes{
    margin-bottom: 25%;
  }
  .rera-details-container {
    margin-bottom: 70%;
  }

  .enquiry-form-title-veda {
    margin: 50px auto 150px;

  }
}
@media (max-width: 650px) {
  .qr-codes{
    margin-bottom: 30%;
  }
}
@media (max-width: 480px) {

  iframe {
    width: 280px;
    height: 298px;
  }

  .request-callback-form {
    margin-top: -30%;
  }

  .enquiry-form-title-veda {
    margin: 50px auto 130px;
    font-size: 1.9rem;

  }
  .qr-codes{
    margin-bottom: 40%;
  }
  .request-callback-form h1 {
    font-size: 2rem;
  }
}

@media (max-width: 320px) {
  iframe {
    width: 250px;
    height: 298px;
  }

  .request-callback-form {
    margin-top: -30%;
  }

  .enquiry-form-title-veda {
    margin: 50px auto 100px;
    font-size: 1.5rem;

  }
  .request-callback-form h1 {
    font-size: 1.3rem;
  }
}

@media (max-width: 270px) {
  iframe {
    width: 200px;
    height: 298px;
  }
}