/* OtherProjectDesktop  */
.OtherProjects {
  text-align: center;
  padding: 10px 60px 94px 60px;
}

.OtherProjects h2 {
  margin-bottom: 60px;
  color: #414042;
  font-family: Montserrat;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 77px;
}

.OtherProjects-slide {
  position: relative;
  overflow: hidden;
}

.OtherProjects-image {
  /* width: 373px;
  height: 486px; */
  width: 100%; 
  height: auto; 
  object-fit: cover;
  border-radius: 0px 40px 0px 0px;
}



.OtherProjects-overlay {
  position: absolute;
  bottom: 0;
  /* width: 373px; */
  /* height: 486px; */
  width: 100%; 
  height: auto; 
  opacity: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 50%
  );
  color: white;
  text-align: center;
  padding: 10px;
  height: 409px;
  border-radius: 0;
  transition: 0.5s ease;
  cursor: pointer;

}

.OtherProjects-slide:hover .OtherProjects-overlay {
  opacity: 1;
}

.OtherProjects-text h3 {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 200px;
  color: white;
}

.OtherProjects-text p {
  font-size: 1.3rem;
  color: white;
  margin-top: 10px;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.OtherProjects-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.OtherProjects-next-arrow {
  right: -10px;
}

.OtherProjects-prev-arrow {
  left: -10px;
}
.OtherProjects-arrow img {
  width: 50px;
  height: 50px;
}

.slick-slide {
  padding: 0 10px;
}

/* Mobile styles */
@media (max-width: 768px) {
    .OtherProjects-arrow img {
      width: 20px;
      height: 20px;
    }
  
    .OtherProjects-next-arrow {
      right: 10px;
    }
  
    .OtherProjects-prev-arrow {
      left: 10px;
    }
  
    .OtherProjects-slide {
      margin-bottom: 20px;
    }
  }

/* OtherProjectMobile css */

.OtherProjectsMobile-section {
  text-align: center;
  padding: 0 15px;
}

.OtherProjectsMobile-section-title {
  margin-bottom: 30px;
  font-family: "Montserrat";
  font-size: 2.188rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 50px;
}

.OtherProjectsMobile-image-grid {
  margin: 0 -15px;
}

.OtherProjectsMobile-image-container {
  position: relative;
  padding: 15px 0;
}

.OtherProjectsMobile-image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.OtherProjectsMobile-project-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.OtherProjectsMobile-image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 50%
  );
  color: white;
  text-align: center;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.OtherProjectsMobile-image-wrapper:hover .OtherProjectsMobile-image-overlay {
  opacity: 1;
}

.OtherProjectsMobile-image-title {
  font-family: "Montserrat";
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  margin-bottom: 20px;
}

.OtherProjectsMobile-location{
  font-family: "Montserrat";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0 auto 20px auto; 
}
.OtherProjectsMobile-image-subtitle {
  font-family: "Montserrat";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-top: 10px;
}

.OtherProjectsMobile-read-more-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  top: -15px;
}

.OtherProjectsMobile-read-more-icon {
  width: 40px !important;
  height: 40px !important;
  position: absolute;
  left: 50%;
  top: -20px;
  transform: translateX(-50%);
  transition: transform 0.3s ease-in-out;
}

.OtherProjectsMobile-read-more-icon.rotated {
  transform: translateX(-50%) rotate(180deg);
  top: -35px;
}
