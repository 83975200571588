.amenities-section-desktop {
  /* padding: 40px 0; */
  position: relative;
}

.amenities-section-desktop-internal-amenities {
  width: 100%;
  background-color: #414042;
  /* height: 571px; */
  color: white;
  padding-top: 20px;
  text-align: center;
  padding-bottom: 150px;
}

.amenities-section-desktop-internal-amenities
  .amenities-section-desktop-section-title {
  font-family: "Montserrat";
  font-size: 2.188rem;
  font-style: normal;
  font-weight: 700;
  margin: 40px 0px;
}

.amenities-section-desktop-external-amenities {
  padding: 20px;
  width: 98%;
  text-align: center;
  background-color: #d9a11a;
  color: #fff;
  /* height: 422px; */
  border-radius: 100px 0px 0px 100px;
  position: relative;
  margin-top: -10%;
  right: -2%;
}

.amenities-section-desktop-external-amenities
  .amenities-section-desktop-section-title {
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 9px;
}

.amenities-section-desktop-external-amenities
  .amenities-section-desktop-sub-title {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 40px;
}

.amenities-section-desktop-amenities-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.text-container {
  padding-bottom: 60px;
}
.amenity-item {
  flex-basis: calc(20% - 20px);
}

.amenities-section-desktop-amenity-item {
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.amenities-section-desktop-amenity-text {
  font-size: 0.875rem;
  font-weight: bold;
  padding-top: 20px;
}

.amenities-section-desktop-amenity-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.amenities-section-desktop-amenity-text {
  position: relative;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding-bottom: 10px;
}

.amenities-section-desktop-amenity-text::after {
  content: "";
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  width: 1.2px !important;
  height: 60px;
  background-color: #fff;
  z-index: 1;
}

/* .amenities-section-desktop-amenities-grid .amenity-item:nth-child(5n) .amenities-section-desktop-amenity-text::after {
  display: none;
} */
.amenities-section-desktop-amenities-grid
  .amenity-item:last-child
  .amenities-section-desktop-amenity-text::after {
  display: none;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .amenities-section-desktop-amenities-grid {
    display: flex;
    flex-wrap: wrap;
  }

  .amenity-item {
    flex: 0 0 calc(33.33% - 10px);
    margin: 5px;
    position: relative;
  }
  .amenities-section-desktop-internal-amenities {
    padding: auto 10px;
  }
  .amenities-section-desktop-external-amenities {
    margin-top: -20%;
    margin-bottom: 60px;
    padding: auto 10px;
  }

  .amenities-section-desktop-internal-amenities
    .amenities-section-desktop-section-title {
    font-size: 2.188rem;
    text-align: center;
    white-space: normal;
    padding: 0px 20px;
  }
  .amenities-section-desktop-external-amenities
    .amenities-section-desktop-section-title {
    font-size: 2.188rem;
    white-space: normal;
    text-align: center;
    padding: 0px 20px;
  }

  .amenities-section-desktop-external-amenities
    .amenities-section-desktop-sub-title {
    font-size: 1.125rem;
    white-space: normal;
  }

  .amenities-section-desktop-amenity-icon {
    width: 50px;
    height: 50px;
  }
  .amenities-section-desktop-amenity-text {
    font-size: 12px;
    padding: 5px;
  }

  .amenities-section-desktop-amenity-text::after {
    right: -5px;
  }

  .amenities-section-desktop-amenities-grid
    .amenity-item:nth-child(3n)
    .amenities-section-desktop-amenity-text::after {
    display: none;
  }

  .amenities-section-desktop-amenities-grid
    .amenity-item:last-child
    .amenities-section-desktop-amenity-text::after {
    display: none;
  }
}

@media (max-width: 650px) {
  .amenities-section-desktop-external-amenities {
    margin-top: -25%;
  }
}
@media (max-width: 550px) {
  .amenities-section-desktop-external-amenities {
    margin-top: -40%;
  }
}
@media (max-width: 450px) {
  .amenities-section-desktop-external-amenities {
    margin-top: -40%;
  }
}
@media (max-width: 350px) {
  .amenities-section-desktop-external-amenities {
    margin-top: -45%;
  }

  .amenities-section-desktop-amenity-icon {
    width: 40px;
    height: 40px;
  }
  .amenities-section-desktop-amenity-text {
    font-size: 12px;
    padding: 5px;
  }

  .amenities-section-desktop-amenity-text {
    font-size: 10px;
    white-space: normal;
  }
  .amenities-section-desktop-amenity-text::after {
    height: 40px;
  }

  .amenities-section-desktop-internal-amenities
    .amenities-section-desktop-section-title {
    font-size: 1.7rem;
  }
  .amenities-section-desktop-external-amenities
    .amenities-section-desktop-section-title {
    font-size: 1.7rem;
  }
}
/* Desktop Styles */
@media (min-width: 768px) {
  .amenities-section-desktop-amenities-grid
    .amenity-item:nth-child(5n)
    .amenities-section-desktop-amenity-text::after {
    display: none; /* Hide line after every fifth item, only for desktop */
  }
}
.see-all-button-external {
  margin: 20px auto;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  background-color: black;
  color: #fff; 
  border-radius: 20px;
  padding: 10px 20px;
  text-transform: none;
  position: relative;
  z-index: 3; 
  border: none;
  box-shadow: none;
}
.see-all-button-internal {
  margin: 20px auto;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  background-color: #d9a11a;
  color: #fff;
  border-radius: 20px;
  padding: 10px 20px;
  text-transform: none;
  position: relative;
  z-index: 3; 
  border: none;
  box-shadow: none;
}

.see-all-button:hover {
  background-color: #b5840f; 
  color: #fff;
}

