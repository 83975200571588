*{
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}
/* body {
  font-family: 'Montserrat' !important;
} */

:root {
  --button-bg-color: #DCA52E;
  --button-hover-color: #DCA52E;
  --div-bg-color:#DCA52E;
  --text-color: #DCA52E;
  --nav-bg-color:#414042;
  --card-vg-color:#F2F0F3;
  --heading-text-color:#414042
}



.blur-background {
  position: relative;
  filter: blur(5px);
}




