.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  box-sizing: border-box;
  z-index: 999999999;
  padding: 0px 20px;
  transition: top 0.3s;
  height: 100px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.01) 0%, #000 100%);
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, black 100%); */
}
.header-version {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center; 
  box-sizing: border-box;
  z-index: 999999999;
  padding: 0px 20px;
  transition: top 0.3s;
  height: 100px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.01) 0%, #000 100%);
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, black 100%); */
}


.left-logo {
  height: 63px;
  max-width: 100%;
  margin-top: 20px;
  cursor: pointer;
}

.right-logo {
  height: 63px;
  max-width: 100%;
  margin-top: 20px;
}

.right-logo-hide {
  /* display: hidden !important; */
  visibility: hidden;
}

.nav-container {
  background-color: var(--nav-bg-color);
  padding: 20px 0;
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 1200px;
  margin: 0 auto;
  /* margin-left: 10%; */
  border-radius: 0px 0px 20px 20px;
  padding: 30px 66px 24px 66px;
}
.nav-container-version {
  background-color: var(--nav-bg-color);
  padding: 30px 66px 24px 66px; 
  display: flex;
  justify-content: center; 
  width: auto;
  max-width: 1200px;
  margin: 0 auto; 
  border-radius: 0px 0px 20px 20px;
}



.nav-link {
  color: var(--text-color);
  margin: 0 15px;
  text-decoration: none;
  font-size: 0.875rem;
  line-height: 100%;
  font-family: "Montserrat";
  transition: color 0.3s ease;
  cursor: pointer;
}

.nav-links a {
  color: var(--text-color);
  margin: 0 15px;
  text-decoration: none;
  font-size: 0.875rem !important;
  line-height: 100%;
  font-family: "Montserrat" !important;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: white;
  font-weight: bold;
}

/* Active state styling */
.nav-links .active-link {
  color: white !important;
  font-weight: bold;
}

.projects-dropdown {
  position: relative;
  display: inline-block;
}

.projects-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  background-color: var(--nav-bg-color);
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  top: 100%;
  left: 0;
  color: var(--text-color);
  z-index: 10;
  padding: 10px 0px;
}

.projects-dropdown:hover .dropdown-menu,
.projects-dropdown .dropdown-menu.show {
  display: block;
}

.dropdown-item {
  padding: 10px;
  text-decoration: none;
  color: #333;
  display: block;
}


.mobile-footer {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  max-width: 600px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  /* background-color: var(--nav-bg-color); */
  padding: 20px 23px;
  text-align: center;
  z-index: 1 !important;
  min-height: 47px;
  max-width: 373px;
  z-index: 999999999 !important;
}
.mobile-footer-version {
  position: fixed;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  max-width: 600px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  /* background-color: var(--nav-bg-color); */
  padding: 20px 23px;
  text-align: center;
  z-index: 1 !important;
  min-height: 47px;
  max-width: 373px;
  z-index: 999999999 !important;
}

.nav-link-mobile {
  color: white;
  margin: 0 10px;
  text-decoration: none;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 700;
}

.mobile-nav-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 700;
}



/* .mobile-nav-links a:hover {
  color: #DCA52E;
} */



.mobile-nav-links .active-link {
  color: #DCA52E !important;
  font-weight: bold;
}


/* Mobile Dropdown Styles */
.projects-dropdown-mobile {
  position: relative;
  display: inline-block;
}

.dropdown-menu-mobile {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  bottom: 100%;
  left: 0;
  z-index: 10;
  /* width: 100%; */
  color: var(--text-color);
  padding: 10px 0px;

}

.projects-dropdown-mobile:hover .dropdown-menu-mobile,
.projects-dropdown-mobile.show .dropdown-menu-mobile {
  display: block;
}

.dropdown-item {
  display: block;
  padding: 10px 5px;
  color: white;
  text-decoration: none;
}

/* .dropdown-item:hover {
  background-color: darkgray;
} */

@media (max-width: 768px) {
  .header {
    display: none;
  }
}

@media (max-width: 768px) {
  .header {
    display: none;
  }
}

.header.hidden {
  top: -100px;
}


@media (max-width: 768px) {
  .mobile-footer {
    padding: 15px 0;
  }

  .mobile-nav-links a {
    font-size: 12px !important;
    margin: 0 6px !important;
  }

  .header {
    display: none;
  }
}

@media (max-width: 480px) {
  .mobile-footer {
    padding: 10px 0;
  }

  .mobile-nav-links a {
    font-size: 10px !important;
    margin: 0 3px;
  }

  .nav-link-mobile {
    font-size: 10px;
  }
}


@media (max-width: 320px) {
  .mobile-footer {
    padding: 8px 0;
    /* min-height: 30px;  */
  }

  .mobile-nav-links a {
    font-size: 8px !important;
    margin: 0 2px;
  }
}

@media (max-width: 270px) {
  .mobile-footer {
    padding: 8px 0;
  }

  .mobile-nav-links a {
    font-size: 7px !important;
    margin: 0 2px;
  }
}