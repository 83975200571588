.homepage {
  position: relative;
}

.carousel {
  height: 100vh;
  width: 100%;
}

.carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 883px; */
  height: 100vh;
  object-fit: cover;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

/* .content {
  position: absolute;
  top: 35%; 
  left: 50%;
  transform: translate(-50%, -50%); 
  text-align: center;
  color: white;
  z-index: 1;
} */

.content {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
  padding: 0 20px;
}
.whereQualityMeetsContainer {
  /* width: 816px; */
  font-size: 4rem;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 900;
  font-family: Montserrat;
  text-align: center;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 1px 2.4px rgba(0, 0, 0, 0.3));
  color: #dca52e;
  margin: 0 auto;
}

.whereQualityMeetsContainer1 {
  width: 100%;
}

.whereQuality {
  margin: 0;
}

.where {
  color: #fff;
}

.dreams {
  color: #dca52e;
}

.meetsYourDreams {
  margin: 0;
  color: #fff;
}



.text-above-button p {
  font-size: 1.5rem; 
  font-weight: 700;
  margin-bottom: 20px;
  padding: 0; 
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); 
}
.ctaButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.enquireButton {
  background-color: #dca52e;
  border: none;
  padding: 0; 
  border-radius: 12px;
  width: 243px;
  height: 53px;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  display: flex;
  align-items: center; 
  justify-content: center; 
  cursor: pointer;
}

@media  (max-width: 768px) {
.whereQualityMeetsContainer{
  font-size: 1.875rem;
  /* width: auto; */

}
}


@media (max-width: 1024px) {
  .whereQualityMeetsContainer{
    font-size: 2.5rem;
    /* width: 500px; */
  
  }
}

