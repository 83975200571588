.ProjectGallery_desktop-slide-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.ProjectGallery_desktop-title {
  font-size: 2.188rem;
  line-height: 120%;
  text-transform: uppercase;
  display: inline-block;
  font-family: "Montserrat";
  color: #414042;
  text-align: center;
  padding-top: 76px;
  padding-bottom: 52px;
}

.ProjectGallery_desktop-swiper {
  width: 100%;
}

.ProjectGallery_desktop-slide {
  position: relative;
}

.ProjectGallery_desktop-image {
  width: 100%;
  height: auto;
  /* height: 517px; */
  object-fit: cover;
}

.ProjectGallery_desktop-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.ProjectGallery_desktop-left-arrow {
  left: 10%;
}

.ProjectGallery_desktop-right-arrow {
  right: 10%;
}

@media (max-width: 992px) {
  .slick-slide {
    padding: 0 10px;
  }
}

@media (max-width: 768px) {
  .ProjectGallery_desktop-arrow {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 554px) {
  .ProjectGallery_desktop-arrow {
    width: 20px;
    height: 20px;
  }
}

/* ProjectGalleryMobile css */
.project-gallery-mobile-container {
  text-align: center;
  margin: 0px 30px !important;
}

.project-gallery-mobile-title {
  color: #414042;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.188rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 34px;
}

.project-gallery-mobile-carousel {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.project-gallery-mobile-image-container {
  position: relative;
  overflow: hidden;
}

.project-gallery-mobile-image {
  width: 100%;
  display: block;
  height: 275px;
  border-radius: 0px 40px 0px 0px;
}

.project-gallery-mobile-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.project-gallery-mobile-left-arrow {
  left: -15px;
}

.project-gallery-mobile-right-arrow {
  right: -15px;
}

.project-gallery-mobile-arrow img {
  width: 30px;
  height: 30px;
}
