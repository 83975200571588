.floor-plans {
  text-align: center;
  padding: 80px;
  background-color: #d9d9d9;
}

.resources-title {
  font-size: 2.188rem;
  line-height: 120%;
  text-transform: uppercase;
  display: inline-block;
  font-family: Montserrat;
  color: #414042;
  margin-top: 103px;
}

.floor-plans-title {
  font-size: 1.875rem;
  line-height: 120%;
  font-family: Montserrat;
  color: #414042;
  margin: 40px 0px;
}

.azatha-floor-plan-slide {
  position: relative;
  overflow: hidden;
  justify-content: center;
}

/* .floor-plan-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
} */

.download-brochure {
  display: inline-block;
  margin-top: 20px;
  font-size: 1.563rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: #414042;
  text-decoration: underline;
  padding: 10px 20px;
  border-radius: 20px;
}

.download-brochure:hover {}


/* Project location */
.floor-plans-project-location-title {
  color: #414042;
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.188rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 70px;
  margin-bottom: 60px;
}

/* RERA Details */
/* .rera-details-container{
  margin-bottom: 70%;
} */
.rera-details-title {
  color: #414042;
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.188rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 60px;
  margin-bottom: 50px;
}

.rera-details-container p {
  color: #414042;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
}

.rera-details-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

/* .rera-details-container {
  text-align: center;
  color: #414042;
  margin-bottom: 30px;
} */

.rera-details-container {
  flex: 1;
  text-align: left;
  color: #414042;
}

.rera-details-title {
  color: #414042;
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.188rem;
  font-weight: 700;
  line-height: 120%;
  margin: 50px auto 20px;
}

.rera-details-container p {
  color: #414042;
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 1rem;
  font-weight: 600;
  line-height: 180%;
  margin: 0;
}

.rera-link {
  text-decoration: underline;
  color: #007bff;
  font-family: "Montserrat" !important;
  font-size: 1rem;
  font-weight: 600;
  line-height: 180%;
}



/*  */

.enquiry-form-title {
  color: #414042 !important;
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.188rem;
  font-weight: 700;
  line-height: 120%;
  margin: 50px auto 20px;
}

.azatha-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 20px 0;
  flex-wrap: wrap;
}

.azatha-image-item {
  position: relative;
  width: 373px;
  /* height: 427px; */
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.azatha-floor-plan-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 0px 40px 0px 0px;
}

.azatha-image-title {
  position: absolute;
  bottom: 1px;
  left: 50%;
  width: 100%;
  /* height: 100%; */
  height: 10%;
  /* border-radius: 0px 40px 0px 0px; */
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 50%
  ); */
  color: #fff;
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px;
  opacity: 0;
  transition: opacity 0.3s;
}

.azatha-image-item:hover .azatha-image-title {
  opacity: 1;
}

/* modal */

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding-top: 10vh;
}

/* Modal content */
.modal-content {
  background: #fff;
  padding: 20px !important;
  position: relative;
  max-width: 90%;
  max-height: 80vh;

  /*   
  width: 80%; 
  height: 80%;
  max-width: 1200px; 
  max-height: 800px; */
  /* margin: 50px auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden;  */
  overflow: auto;
}

/* Modal image */
.modal-image {
  padding-top: 50px;
  max-width: 100%;
  max-height: 90vh;
  display: block;
}


/* Zoom Controls */
.zoom-controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
}

.zoom-button img {
  width: 20px;
  height: 20px;
}

.zoom-button {
  background: #fff;
  /* border: 1px solid #ccc; */
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 1px;
  font-size: 24px;
  cursor: pointer;
  color: #000;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.floorplan-aazastha-qr-code-image  {
  width: 150px;
  height: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999999999999 !important;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image-container {
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  /* max-width: none; */
  /* Removes constraints to allow image to be larger */
  max-height: none;
  /* Removes constraints to allow image to be larger */
  cursor: zoom-out;
  transition: transform 0.3s ease;
}

.modal-image.zoomed {
  transform: scale(2);
  /* Adjust the scale as needed */
  transform-origin: center;
  /* Keeps the image centered */
}

/* Callback form Css */

.request-callback-form {
  background-color: #d19936;
  padding: 40px;
  display: flex;
  position: relative;
  margin: 0px 30px;
  justify-content: center;
  align-items: center;
  margin-top: -5%;
  z-index: 9999999 !important;
}

.callback-form-container {
  display: flex;
  align-items: center;
}

.callback-logo img {
  width: 80px;
  height: 80px;
}

.callback-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  gap: 10px;
}

.callback-form input,
.callback-form select {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
}

.callback-form select {
  flex-basis: 100%;
}

.callback-form button {
  padding: 10px 20px;
  background-color: #414042;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.callback-form button:hover {
  background-color: #333;
}

.callback-form input[type="text"]:first-child,
.callback-form select {
  flex: 1;
}

.callback-form input[type="text"]:nth-child(3),
.callback-form input[type="email"],
.callback-form button {
  flex: 1;
}

.callback-form select {
  flex-basis: calc(50% - 5px);
}

.callback-form input[type="text"]:first-child {
  flex-basis: calc(50% - 5px);
}


@media (max-width: 1024px) {
  iframe {
    width: 600px;
    height: 298px;
  }
}

@media (min-width: 1025px) {
  iframe {
    width: 1161px;
    height: 463px;
    border-radius: 0px 40px 0px 0px;
  }
}


@media (max-width: 768px) {

  /* Adjust the overall padding */
  .floor-plans {
    padding: 40px;
  }

  .request-callback-form {
    margin-top: -20%;
  }

  /* Adjust the titles */
  .resources-title {
    margin-top: 30px;
    font-size: 2.188rem;
  }

  .floor-plans-title {
    font-size: 1.563rem;
    margin: 30px;
  }

  /* Adjust the Slick slider elements */
  .slick-slide {
    padding: 0;
  }

  .prev-slide-arrow img,
  .next-slide-arrow img {
    width: 40px;
    height: 40px;
  }

  .floor-plan-slide {
    margin-bottom: 20px;
  }

  .callback-form-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  /* Logo adjustments */
  .callback-logo {
    margin-bottom: 20px;
  }

  .callback-logo img {
    width: 60px;
    height: 60px;
  }

  /* Form adjustments */
  .callback-form {
    margin-left: 0;
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .callback-form input,
  .callback-form select,
  .callback-form button {
    width: 100%;
    flex: none;
  }

  .floor-plans {
    padding: 40px;
  }

  .resources-title {
    margin-top: 30px;
    font-size: 2.188rem;
  }

  .floor-plans-title {
    font-size: 1.563rem;
    margin: 30px;
  }

  .slick-slide {
    padding: 0;
  }

  .prev-slide-arrow img,
  .next-slide-arrow img {
    width: 40px;
    height: 40px;
  }

  .floor-plan-slide {
    margin-bottom: 20px;
  }

  iframe {
    width: 350px;
    height: 298px;
  }


  .rera-details-container {
    margin-bottom: 70%;
  }

  .rera-details-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .rera-details-container {
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 0;
  }

  .floorplan-aazastha-qr-code-image {
    max-width: 50%;
    height: auto;
    margin: 0 auto 20%;
  }

  .enquiry-form-title {
    margin: 50px auto 150px;
    font-size: 2rem;
  }

  .rera-details-title {
    font-size: 2rem;
  }

  .floor-plans-project-location-title {
    font-size: 2rem;
  }
}

@media (max-width:650px) {
  .request-callback-form {
    margin-top: -25%;
  }
  .floorplan-aazastha-qr-code-image {
    max-width: 50%;
    height: auto;
    margin: 0 auto 25%;
  }
}

@media (max-width:550px) {
  .request-callback-form {
    margin-top: -25%;
  }
  .floorplan-aazastha-qr-code-image {
    max-width: 50%;
    height: auto;
    margin: 0 auto 30%;
  }
}

@media (max-width: 480px) {
  iframe {
    width: 280px;
    height: 298px;
  }

  .request-callback-form {
    margin-top: -30%;
  }


  .enquiry-form-title {
    margin: 50px auto 130px;
    font-size: 1.9rem;
  }

  .rera-details-title {
    font-size: 1.9rem;
  }

  .floor-plans-project-location-title {
    font-size: 1.9rem;
  }
  .floorplan-aazastha-qr-code-image {
    max-width: 50%;
    height: auto;
    margin: 0 auto 40%;
  }
}


@media (max-width: 380px) {
  .enquiry-form-title {
    margin: 50px auto 90px;
    font-size: 1.9rem;
  }
  .floorplan-aazastha-qr-code-image {
    max-width: 50%;
    height: auto;
    margin: 0 auto 45%;
  }
}

@media (max-width: 320px) {
  iframe {
    width: 250px;
    height: 298px;
  }

  .enquiry-form-title {
    margin: 50px auto 100px;
    font-size: 1.5rem;
  }

  .rera-details-title {
    font-size: 1.5rem;
  }

  .floor-plans-project-location-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 270px) {
  iframe {
    width: 200px;
    height: 298px;
  }
}