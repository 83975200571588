.in-their-own-words-desktop {
  background-color: var(--nav-bg-color);
  color: white;
  padding: 40px 0px;
  /* text-align: center; */
  position: relative;
}

.in-their-own-words-desktop-header {
  display: flex;
  justify-content: right;
}

.in-their-own-words-desktop-text-container {
  text-align: center;
  margin-top: 1rem;
}

.in-their-own-words-desktop-text-container p {
  margin-bottom: 10px;
  font-size: 60px;
  font-family: "Montserrat";
  font-weight: 700;
}

.in-their-own-words-desktop-text-container span {
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 300;
}

.in-their-own-words-desktop-gray-quote-right {
}

.in-their-own-words-desktop-gray-quote-left {
  margin: 40px 40px -40px 40px;
}

.in-their-own-words-desktop-testimonial-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.in-their-own-words-desktop-testimonial,
.in-their-own-words-desktop-testimonial2 {
  position: relative;
}

.in-their-own-words-desktop-testimonial-content {
  display: flex;
  gap: 20px;
}

.in-their-own-words-desktop-testimonial-content.reverse {
  flex-direction: row-reverse;
}

.in-their-own-words-desktop-testimonial-text {
  flex: 1;
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: 400;
  margin: 20px 60px;
}
.in-their-own-words-desktop-testimonial-text2 {
  flex: 1;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 400;
}

.in-their-own-words-desktop-testimonial-text p {
  text-align: start;
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: 400;
}
.in-their-own-words-desktop-testimonial-text2 p {
  text-align: start;
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: 400;
  margin-top: 50px;
  padding: 0px 100px 0px 250px;
}

.in-their-own-words-desktop-testimonial-image1 {
  flex: 1;
  object-fit: cover;
  padding-top: 40px;
}
.in-their-own-words-desktop-testimonial-image2 {
  flex: 1;
  object-fit: cover;
}

.in-their-own-words-desktop-caring-beyond-business {
  background-color: var(--div-bg-color);
  color: white;
  padding: 40px;
  border-radius: 0px 200px 200px 0px;
  margin-top: -100px;
  position: relative;
  left: -20px;
  width: 95%;
  display: flex;
  justify-content: center;
}

.in-their-own-words-desktop-caring-content {
  text-align: start;
  max-width: 80%;
  width: 100%;
}

.in-their-own-words-desktop-caring-content-title {
  margin-bottom: 10px;
  font-size: 60px;
  font-family: "Montserrat";
  font-weight: 700;
}

.in-their-own-words-desktop-caring-beyond-business p {
  font-size: 20px;
  font-family: "Montserrat" !important;
  font-weight: 600;
}
/* 1495 */
@media (min-width: 1441px) and (max-width: 2560px) {
  .in-their-own-words-desktop-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .in-their-own-words-desktop-text-container p {
    font-size: 80px !important;
  }
  .in-their-own-words-desktop-text-container p {
    font-size: 0px;
  }

  .in-their-own-words-desktop-testimonial-text p {
    font-size: 30px;
  }

  .in-their-own-words-desktop-testimonial-text {
    margin: 40px 100px;
  }

  .in-their-own-words-desktop-testimonial-text2 p {
    font-size: 30px;
  }

  .in-their-own-words-desktop-testimonial-image1 {
    height: auto;
  }

  .in-their-own-words-desktop-testimonial-image2 {
    height: auto;
  }

  .in-their-own-words-desktop-caring-content-title {
    margin-bottom: 10px;
    font-size: 60px;
  }

  .in-their-own-words-desktop-caring-beyond-business p {
    font-size: 25px;
  }
}

@media (max-width: 1024px) {
  .in-their-own-words-desktop-text-container p {
    font-size: 60px !important;
  }

  .in-their-own-words-desktop-gray-quote-right {
    width: 30%;
    height: auto;
  }

  .in-their-own-words-desktop-testimonial-text {
    margin: 20px 40px;
  }

  .in-their-own-words-desktop-testimonial-text2 p {
    font-size: 20px;
  }

  .in-their-own-words-desktop-testimonial-text2 p {
    padding: 0px 40px 0px 100px;
  }

  .in-their-own-words-desktop-caring-content-title {
    font-size: 40px;
  }

  .in-their-own-words-desktop-caring-beyond-business p {
    font-size: 16px;
  }
}

/* Mobile View css for InTheirOwnWords */

.in-their-own-words-mobile {
  background-color: var(--nav-bg-color);
  color: white;
  position: relative;
  box-sizing: border-box;
  /* height: 2300px; */
  /* overflow-x: hidden; */
  overflow-y: hidden;
}

.in-their-own-words-mobile-header {
  text-align: center;
  margin-bottom: 2.188rem;
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 50px;
}

.in-their-own-words-mobile-text-container span {
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
}

.in-their-own-words-mobile-testimonial-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.in-their-own-words-mobile-testimonial {
  position: relative;
  /* padding: 15px; */
  border-radius: 15px;
}

.in-their-own-words-mobile-text-left {
  text-align: left;
  padding: 15px;
  margin-bottom: 15px;
}

.in-their-own-words-mobile-image-right {
  width: 80%;
  height: auto;
  margin-left: auto;
  display: block;
  border-radius: 10px;
}

.in-their-own-words-mobile-text-right {
  text-align: right;
  margin-bottom: 15px;
  padding: 15px;
}

.in-their-own-words-mobile-text-right p {
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 174%; /* 34.8px */
  letter-spacing: -0.6px;
}
.in-their-own-words-mobile-image-left {
  width: 80%;
  height: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 45%;
}

.in-their-own-words-mobile-text-left p {
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 174%; /* 34.8px */
  letter-spacing: -0.6px;
}

.in-their-own-words-mobile-caring-beyond-business {
  background-color: var(--div-bg-color);
  border-radius: 100px 0px 0px 100px;
  /* height: 459px; */
  color: white;
  padding: 20px;
  margin-top: 20px;
  position: relative;
  padding: 40px 45px;
  width: 96%;
  right: -4%;
  z-index: 99999999 !important;
  margin-top: -45%;
}
.in-their-own-words-mobile-gray-quote-top,
.in-their-own-words-mobile-gray-quote-bottom {
  position: absolute;
  width: 50px;
  height: auto;
}

.in-their-own-words-mobile-gray-quote-top {
  top: -15px;
  right: -15px;
}

.in-their-own-words-mobile-gray-quote-bottom {
  bottom: -15px;
  left: -15px;
}

.in-their-own-words-mobile-caring-content {
  text-align: center;
}

.in-their-own-words-mobile-caring-content-title {
  font-weight: 700;
  font-family: Montserrat;
  font-size: 2.188rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 50px;
}

.in-their-own-words-mobile-caring-beyond-business p {
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-top: 35px;
  margin-bottom: 20px;
}
