.introduction-section {
  padding: 40px 20px;
  text-align: center;
  box-sizing: border-box; 
}

.text-content-wrapper {
  width: 100%;
  max-width: 899px; 
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box; 
}

.highlight-section {
  width: 100%;
  max-width: 1020px; 
  margin: 40px auto 0;
  text-align: center;
  box-sizing: border-box; 
}

.section-title {
  font-size: 60px;
  font-weight: bold;
  line-height: 120%;
  font-family: Montserrat;
  margin-bottom: 34px;
}

.section-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 180%;
  color: var(--heading-text-color);
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  margin-bottom: 15px;
  box-sizing: border-box;
}

.section-highlight {
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 180%;
  color: var(--text-color);
  margin-top: 20px;
  box-sizing: border-box; 
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .introduction-section {
    padding: 30px 15px;
  }

  .section-title {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .text-content-wrapper {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }

  .highlight-section {
    width: 100%;
    max-width: 350px;
    margin: 20px auto 0;
  }

  .section-text {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .section-highlight {
    font-size: 1.1rem;
    margin-top: 15px;
  }
}

@media (max-width: 480px) {
  .introduction-section {
    padding: 20px 10px;
  }

  .section-title {
    font-size: 1.75rem;
    margin-bottom: 10px;
  }

  .text-content-wrapper {
    width: 100%;
    max-width: 352px;
    margin: 0 auto;
  }

  .highlight-section {
    width: 100%;
    max-width: 350px;
    margin: 10px auto 0;
  }

  .section-text {
    font-size: 0.9rem;
    margin-bottom: 8px;
  }

  .section-highlight {
    font-size: 1rem;
    margin-top: 10px;
  }
}
