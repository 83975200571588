body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .privacy-policy {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid #dca52e;
    margin-top: 6%;
  }
  
  .privacy-policy h1 {
    font-size: 2.5em;
    color: #dca52e;
    text-align: center;
  }
  
  .privacy-policy h2 {
    font-size: 1.8em;
    color: #dca52e;
    margin-top: 20px;
  }
  
  .privacy-policy h3 {
    font-size: 1.5em;
    color: #dca52e;
    margin-top: 15px;
  }
  
  .privacy-policy p {
    font-size: 1em;
    line-height: 1.6;
    color: #555;
  }
  
  .privacy-policy ul {
    list-style: none;
    padding: 0;
  }
  
  .privacy-policy ul li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    color: #444;
  }
  
  .privacy-policy ul li:before {
    content: "•";
    position: absolute;
    left: 0;
    top: 0;
    color: #dca52e;
    font-size: 1.2em;
  }
  
  .privacy-policy a {
    color: #dca52e;
    text-decoration: none;
    font-weight: bold;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  
  .privacy-policy {
    transition: all 0.3s ease;
  }
  
  .privacy-policy:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  