/* CompletedProjectsDesktop css */

.completed-projects {
  text-align: center;
  background: #d9d9d9;
  /* padding: 10px 60px 94px 60px; */
  padding: 10px 60px 150px 60px;
}

.desktop-masterpiece-image-container {
  position: relative;
  padding: 30px 0;
}

/* Add this new class */
.desktop-masterpiece-image-container.bottom-half-bg::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%; /* Adjust this percentage to control the height */
  background: #d9d9d9;
  z-index: -1; /* Ensure the background is behind the content */
  transition: transform 0.3s ease-in-out;
}

/* Ensure that the overlay and content are above the pseudo-element */
.desktop-masterpiece-image-wrapper {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
}

.desktop-masterpiece-image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 50%
  );
  color: white;
  text-align: center;
  padding: 10px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.desktop-masterpiece-image-wrapper:hover .desktop-masterpiece-image-overlay {
  opacity: 1;
  transform: scale(1.05);
}

.completed-projects h2 {
  /* margin-bottom: 100px;
  margin-top: 60px; */
  margin: 65px auto 60px auto;
  color: #414042;
  font-family: Montserrat;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.project-slide {
  position: relative;
  overflow: hidden;
  border-radius: 0 40px 0 0;
}

.project-image {
  width: 100%;
  height: auto;
  /* width: 373px;
    height: 486px; */
  object-fit: cover;
  border-radius: 0px 40px 0px 0px;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* width: 373px;
    height: 486px; */
  width: 100%;
  height: auto;
  opacity: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), #000);
  color: white;
  text-align: center;
  padding: 10px;
  height: 409px;
  border-radius: 0;
  flex-direction: column;
  justify-content: flex-end;
  /* transition: 0.5s ease; */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  cursor: pointer;
}

.overlay:hover .text {
  transform: scale(1.09);
}

.text h3 {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  transition: transform 0.3s ease-in-out;
}

.text p {
  font-size: 1.3rem;
  color: white;
  transition: transform 0.3s ease-in-out;
}

/* .project-slide:hover .overlay,
.overlay {
  opacity: 1;
  transform: scale(1.05);
} */

.overlay:hover,
.overlay {
  opacity: 1;
  transform: scale(1.05);
}

.text h3 {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 200px;
  color: white;
}

.text p {
  font-size: 1.3rem;
  color: white;
  margin-top: 10px;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.next-arrow {
  right: -5px;
}

.prev-arrow {
  left: -10px;
}

.arrow img {
  width: 40px;
  height: 40px;
}

.slick-slide {
  padding: 0 10px;
}

.back-to-top {
  /* position: fixed; */
  bottom: 20px;
  right: 20px;
  margin-top: 90px;
  background: #b6b8b9;
  border: none;
  width: 176px;
  height: 31px;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: background-color 0.3s ease;
}

.back-to-top:hover {
  background-color: #d19936;
}

/* Mobile styles */
@media (max-width: 768px) {
  .slick-slide {
    padding: 0;
  }

  .next-arrow {
    right: 10px;
  }

  .prev-arrow {
    left: 10px;
  }

  .arrow img {
    width: 20px;
    height: 20px;
  }

  .project-slide {
    margin-bottom: 20px;
  }
}

/* CompletedProjectsMobile css */

.mobile-completed-projects-section {
  text-align: center;
  padding: 10px 25px;
  background: #d9d9d9;
}

.mobile-completed-projects-section-title {
  margin-bottom: 10px;
  font-family: "Montserrat";
  font-size: 2.188rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 80px;
  margin-bottom: 70px;
}

.mobile-completed-projects-image-grid {
  margin: 0 -15px;
}

.mobile-completed-projects-image-container {
  position: relative;
  padding: 10px 0px;
}

.mobile-completed-projects-image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.mobile-completed-projects-project-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.mobile-completed-projects-image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), #000);
  color: white;
  text-align: center;
  padding: 10px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
}

.mobile-completed-projects-image-wrapper:hover
  .mobile-completed-projects-image-wrapper {
  opacity: 1;
  transform: scale(1.05);
}
/* .mobile-completed-projects-image-wrapper:hover
  .mobile-completed-projects-image-overlay {
  opacity: 1;
} */

.mobile-completed-projects-image-title {
  font-family: "Montserrat";
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  transition: transform 0.3s ease-in-out;
}

.mobile-completed-projects-image-subtitle {
  font-family: "Montserrat";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-top: 10px;
  margin-bottom: 40px;
  transition: transform 0.3s ease-in-out;
}

.mobile-completed-projects-read-more-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  top: -15px;
}

.mobile-completed-projects-read-more-icon {
  width: 50px !important;
  height: 50px !important;
  position: absolute;
  left: 50%;
  top: -20px;
  transform: translateX(-50%);
  transition: transform 0.3s ease-in-out;
}

.mobile-completed-projects-read-more-icon.rotated {
  transform: translateX(-50%) rotate(180deg);
  top: -35px;
}

.mobile-completed-projects-image-wrapper:hover
  .mobile-completed-projects-image-title,
.mobile-completed-projects-image-wrapper:hover
  .mobile-completed-projects-image-subtitle {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 1000px) {
  .request-callback-form1 {
    margin-top: -15% !important;
  }
}
@media (max-width: 900px) {
  .request-callback-form1 {
    margin-top: -18% !important;
  }
}

@media (max-width: 768px) {
  .request-callback-form1 {
    margin-top: -25% !important;
  }
}
@media (max-width: 600px) {
  .mobile-completed-projects-image-title {
    font-size: 1.5rem;
  }

  .mobile-completed-projects-section {
    padding: 10px 25px 160px 25px !important;
  }
  .request-callback-form1 {
    margin-top: -35% !important;
  }
}


@media (max-width: 500px) {

  .mobile-completed-projects-section {
    padding: 10px 25px 200px 25px !important;
  }
  .request-callback-form1 {
    margin-top: -60% !important;
  }
}


@media (max-width: 400px) {

  .mobile-completed-projects-section {
    padding: 10px 25px 150px 25px !important;
  }
  .request-callback-form1 {
    margin-top: -60% !important;
  }
}