.container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0px 60px 60px 60px;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.card {
  text-align: center;
  background-color: var(--card-vg-color) !important;
  padding: 20x;
  width: 275px;
  /* height: 151px; */
  margin: 8px;
  border-radius: 16px !important;
  border-color: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}



.card.animate {
  opacity: 1;
  animation: fadeIn 0.5s ease-out forwards;
}

/* Optional: Add a delay to animations for staggered entrance */
.card:nth-child(1).animate {
  animation-delay: 0s;
}
.card:nth-child(2).animate {
  animation-delay: 0.2s;
}
.card:nth-child(3).animate {
  animation-delay: 0.3s;
}
.card:nth-child(4).animate {
  animation-delay: 0.4s;
}

.card.no-pointer {
  cursor: default;
}

.card:hover {
  background-color: var(--button-hover-color) !important;
  color: #ffffff;
  border-radius: 16px !important;
}

.card:hover .subtitle {
  color: #ffffff;
}
.title {
  font-size: 2.25rem;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Montserrat";
  margin-top: 20px;
}

.subtitle {
  color: #666;
  font-size: 1rem;
  line-height: 120%;
  font-family: Montserrat;
  margin-top: 13px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .container {
    margin: 5px;
    padding: 0px 20px;
  }
  .card {
    flex: 1 1 calc(50% - 16px);
    margin: 8px;
  }

  .title {
    font-size: 22px;
  }
  .subtitle {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .container {
    padding: 0px 10px;
  }
  .card {
    width: calc(100% - 16px);
    padding: 15px;
    margin: 4px;
  }

  .title {
    font-size: 14px;
    margin-top: 0px;
  }
  .subtitle {
    font-size: 12px;
    margin-top: 10px;
  }
}


@media (max-width: 250px) and (min-width: 230px) {
  .container {
    padding: 0px 5px; 
  }
  .card {
    width: calc(100% - 10px); 
    padding: 12px;
    margin: 2px; 
  }

  .title {
    font-size: 12px; 
    margin-top: 10px; 
  }
  .subtitle {
    font-size: 10px; 
    margin-top: 8px; 
  }
}

@media (max-width: 229px) {
  .container {
    padding: 0px 5px; 
  }
  .card {
    width: calc(100% - 10px); 
    padding: 10px;
    margin: 2px; 
  }

  .title {
    font-size: 14px; 
    margin-top: 8px; 
  }
  .subtitle {
    font-size: 8px;
    margin-top: 6px;
  }
}