.contact-us-form {
  padding: 93px 50px;
  background-color: #f5f5f5;
  overflow: hidden;
}

.contact-us-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
}

.map-container {
  /* flex: 1; */
  flex: 2;
}

.map-container iframe {
  border-radius: 0 !important;
  width: 100%;
  height: 100%;
}

.map-container iframe {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.contact-details {
  flex: 2;
  text-align: left;
}

.contact-details h2 {
  color: var(--heading-text-color);
  font-family: "Montserrat";
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  margin-bottom: 20px;
}

.contact-info-section-subtitle {
  color: var(--text-color);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 174%;
}

.contact-info-section p {
  color: #414042;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 174%;
}

.contact-info {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
}

.contact-info-section {
  flex: 1;
}

.contact-info-section p {
  font-family: "Montserrat";
  font-size: 1rem;
  margin-bottom: 10px;
}

.opening-hours {
  /* margin-bottom: 10px; */
}

.opening-hours strong {
  font-family: "Montserrat";
  font-size: 1rem;
}

.opening-hours p {
  font-family: "Montserrat";
  font-size: 1rem;
  margin-top: 5px;
}

.callback-button {
  background-color: var(--button-bg-color);
  width: 178px;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  color: white;
  font-family: "Montserrat";
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 600;
  line-height: 174%;
  cursor: pointer;
}

.enquiry-form-section {
  padding: 20px 0px;
}

.error {
  color: red;
}




.legend {
  color: #414042;
  font-family: "Montserrat" !important;
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 400;
  line-height: 174%;
}
.form-controls-container {
  display: flex;
  gap: 20px;
}

.form-control {
  flex: 1;
}

.radio-group {
  display: flex;
  flex-wrap: nowrap;
  gap: 0px !important;
  width: 120%;
}

.radio-button {
  font-size: 0.75rem;
  color: #414042;
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 174%;
}

.radio-button .MuiSvgIcon-root {
  font-size: 1rem;
}

.enquiry-form-section h2 {
  color: var(--heading-text-color);
  font-family: "Montserrat";
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  margin-bottom: 20px;
}

.form-control {
  margin-bottom: 20px;
}

.send-button {
  width: 117px;
  background-color: var(--button-bg-color);
  padding: 10px 20px;
  border: none;
  margin-top: 20px;
  color: white;
  font-family: "Montserrat";
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 600;
  line-height: 174%;
  cursor: pointer;
}

.custom-textfield .MuiInputBase-input {
  padding: 15px;
}

.custom-textfield .MuiFormLabel-root {
  font-size: 0.75rem;
  color: #414042;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  /* margin-bottom: 4px;  */
}

.custom-textfield .MuiInputLabel-root {
  margin-bottom: 4px;
}

.custom-textfield {
  margin-bottom: 16px;
}
/* Style for radio button labels */
.radio-group .MuiFormControlLabel-label {
  font-family: "Montserrat" !important;
  font-size: 0.75rem !important;
  color: #414042 !important;
  font-weight: 400 !important;
}

.radio-button .MuiSvgIcon-root {
  font-size: 1rem !important;
  color: #414042 !important;
}


.map-container h2 {
  color: var(--heading-text-color);
  font-family: "Montserrat";
  font-size: 2.5rem;
  font-style: normal;
  align-items: center;
  font-weight: 800;
  line-height: 120%;
  margin-bottom: 20px;
  display: none !important;
}

/* Mobile view adjustments */
@media (max-width: 600px) {
  .contact-us-form {
    padding: 60px 20px !important;
  }


  .enquiry-form-section {
    padding:  20px !important;

  }
  .contact-us-section {
    padding: 20px;
  }

  .form-controls-container {
    flex-direction: column;
    overflow: hidden;
  }


  .map-container h2 {
    display: block !important; 
  }

  .contact-details h2{
    display: none !important;
  }

  .form-control {
    margin-bottom: 20px;
  }
  .contact-us-section {
    flex-direction: column;
  }

  .map-container {
    width: 100%;
    height: 300px;
  }



  .contact-details {
    width: 100%;
    margin-top: 20px;
  }
}
