.mobile-masterpiece-section {
  text-align: center;
  padding: 30px 15px;
  overflow: hidden;
}

.mobile-masterpiece-section-title {
  /* margin-bottom: 10px; */
  font-family: "Montserrat";
  font-size: 2.188rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 25px;
  margin-bottom: 50px;
}

.mobile-masterpiece-section-subtitle {
  color: #414042;
  font-family: "Montserrat";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin-bottom: 60px;
}

.mobile-masterpiece-image-grid {
  margin: 0 -15px;
}

.mobile-masterpiece-image-container {
  position: relative;
  padding: 15px 0;
}

.mobile-masterpiece-image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.mobile-masterpiece-project-image {
  width: 100%;
  height: auto;
  /* width: 350px;
  height: 476px; */
  object-fit: cover;
}

.mobile-masterpiece-image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 50%
  );
  color: white;
  height: 409px;
  text-align: center;
  padding: 10px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  display: flex; 
  flex-direction: column; 
  justify-content: flex-end; 
  cursor: pointer;
}

.mobile-masterpiece-image-wrapper:hover .mobile-masterpiece-image-overlay {
  opacity: 1;
  transform: scale(1.05); 

}

.mobile-masterpiece-image-title {
  font-family: "Montserrat";
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  margin-top: 200px;
  line-height: 120%;
}

.mobile-masterpiece-image-subtitle {
  font-family: "Montserrat";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-top: 10px;
  margin-bottom: 15px;
}

.mobile-masterpiece-read-more-button {
  background: none;
  border: none;
  cursor: pointer;
  /* margin-top: 10px; */
  position: relative;
  top: -15px;
}

.mobile-masterpiece-read-more-icon {
  width: 60px !important;
  height: 60px !important;
  position: absolute;
  left: 50%;
  top: -30px;
  transform: translateX(-50%);
  transition: transform 0.3s ease-in-out;
}

.mobile-masterpiece-read-more-icon.rotated {
  transform: translateX(-50%) rotate(180deg);
  top: -45px;
}

.mobile-masterpiece-additional-content {
  margin-top: 10px;
}
