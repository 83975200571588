.mobile-homepage {
  position: relative;
  height: 100vh;
  overflow: hidden;
  
}

.mobile-carousel {
  height: auto;
  width: 100%;
  overflow: hidden;
}

.mobile-carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.mobile-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobile-header {
  position: absolute;
  top:0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: auto;
  z-index: 1;
  height: 283px;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, black 100%);

  
}

.mobile-logo {
  margin-top: 30px !important;
  height: 60px;
}

.mobile-content {
  position: absolute;
  bottom: 45%;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  color: white;
  z-index: 1;
}

.mobile-headline {
  font-family: "Montserrat";
  font-weight: 900;
  text-shadow: 0px 1px 2.4px rgba(0, 0, 0, 0.3);
  font-family: Montserrat;
  font-size: 2rem;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  text-transform: uppercase;
}

.mobile-highlight {
  color: var(--text-color);
  text-shadow: 0px 1px 2.4px rgba(0, 0, 0, 0.3);
  font-family: Montserrat;
  font-size: 2rem;
  font-style: normal;
  font-weight: 900;
  line-height: 100%; /* 30px */
  text-transform: uppercase;
}

.mobile-subtext {
  margin-top: 10px;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.mobile-text-above-button p{
  font-size: 1rem; 
  font-weight: 700;
  margin-bottom: 20px;
  padding: 0; 
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); 
}

.mobile-enquire-button {
  margin-top: 10px;
  border: none;
  background-color: var(--button-bg-color) !important;
  color: white;
  padding: 16px 30px;
  font-size: 0.875rem;
  border-radius: 12px;
  font-family: Montserrat;
  font-weight: 700;
}
