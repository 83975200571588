/* Desktop Styles */
.desktop-ProjectPageCarousel-container {
    position: relative;
    width: 100%;
    z-index: 10;
  }
  
  .desktop-ProjectPageCarousel-carousel {
    width: 100%;
    height: 100%;
  }
  
.desktop-ProjectPageCarousel-carousel-image,
.mobile-ProjectPageCarousel-carousel-image {
  width: 100%;
  height: 760px;
  object-fit: cover;
  filter: none;
  transform: none;
  transition: none;
  image-rendering: optimizeQuality;
}

.desktop-ProjectPageCarousel-carousel-item img,
.mobile-ProjectPageCarousel-carousel-item img {
  filter: none;
  opacity: 1;
  transform: none;
}

.desktop-ProjectPageCarousel-carousel,
.mobile-ProjectPageCarousel-carousel {
  overflow: hidden;
}

.desktop-ProjectPageCarousel-carousel-item,
.mobile-ProjectPageCarousel-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

  
  .desktop-ProjectPageCarousel-details-section {
    background-color: #333;
    color: #fff;
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    height: auto; 
    padding: 40px 60px; 
    margin-top: -100px; 
    z-index: 20; 
    position: relative; 
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.3); 
  }
  
  
  .desktop-ProjectPageCarousel-details-item {
    flex: 1 1 auto;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  
  .desktop-ProjectPageCarousel-details-item-title {
    color: #dca52e;
    font-family: Montserrat;
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 8px;
  }
  
  .desktop-ProjectPageCarousel-details-item-description {
    color: #fff;
    font-family: Montserrat;
    font-size: 1rem;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
  }
  
  .desktop-ProjectPageCarousel-details-item:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 62px;
    width: 1px;
    background-color: #fff;
  }
  
  /* Mobile Styles */
.mobile-ProjectPageCarousel-container {
  position: relative;
  width: 100%;
  /* height: 726px; */

}

.mobile-ProjectPageCarousel-carousel {
  width: 100%;
  height: 100%;
}


.mobile-ProjectPageCarousel-details-section {
  background-color: #333;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  margin-top: -5px;
}

.grid-container {
  /* border-top: 1px solid #fff; */
  /* border-left: 1px solid #fff; */
}

.mobile-ProjectPageCarousel-details-item {
  box-sizing: border-box;
  padding: 10px;
  text-align: left;
  position: relative;
  border-bottom: 1px solid #fff;
}

.mobile-ProjectPageCarousel-details-section .mobile-ProjectPageCarousel-details-item:last-child {
  border-bottom: 0;
}

.mobile-ProjectPageCarousel-details-item.left {
  border-right: 0;
}

.mobile-ProjectPageCarousel-details-item.right {
  border-left: 1px solid #fff;
}

.mobile-ProjectPageCarousel-details-item-title {
  color: #dca52e;
  font-family: Montserrat;
  font-size: 0.813rem;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 5px;
}

.mobile-ProjectPageCarousel-details-item-description {
  color: #fff;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 400;
  line-height: 120%;
}

.full-width {
  text-align: center;
}

@media (min-width: 600px) {
  .mobile-ProjectPageCarousel-details-item {
    padding: 20px;
  }
}
