.overview-section {
  text-align: center;
  padding: 60px 20px; 
  color: #333;
}

.overview-section h1 {
  font-size: 4rem;
  font-family: Montserrat;
  font-weight: 900;
  margin-top: 50px; 
  margin-bottom: 20px;
  line-height: 100%;
}

.overview-section h2 {
  font-size: 1.8rem;
  font-family: "Montserrat";
  font-weight: 300;
  color: #414042;
  font-style: normal;
  line-height: 120%;
  margin-top: 30px;
  margin-bottom: 69px;
}

.overview-section p {
  font-size: 0.875rem;
  font-family: "Montserrat";
  font-weight: 600;
  line-height: 180%;
  max-width: 970px;
  margin: 0 auto 20px auto;
}

.read-more-button-container {
  text-align: center;
  max-width: 1440px;
}

.overview-section button {
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: underline;
  font-size: 1rem;
  font-family: "Montserrat";
  margin-top: 10px;
  font-weight: bold;
  cursor: pointer;
  background-color: white;
}

.overview-section button:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .overview-section h1 {
    font-size: 3rem;
    margin-top: 40px; 
  }

  .overview-section h2 {
    font-size: 1.6rem;
    margin-top: 20px;
  }

  .overview-section p {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .overview-section h1 {
    font-size: 2.5rem;
    margin-top: 30px;
  }

  .overview-section h2 {
    font-size: 1.4rem;
    margin-top: 15px;
  }

  .overview-section p {
    font-size: 1rem;
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .overview-section h1 {
    font-size: 2rem;
    margin-top: 25px;
  }

  .overview-section h2 {
    font-size: 1.2rem;
    margin-top: 15px;
  }

  .overview-section p {
    font-size: 0.9rem;
    max-width: 95%;
    margin: 0 auto 15px auto;
  }
}

@media (max-width: 576px) {
  .overview-section h1 {
    font-size: 1.5rem;
    margin-top: 20px;
  }

  .overview-section h2 {
    font-size: 1rem;
    margin-top: 10px;
  }

  .overview-section p {
    font-size: 0.8rem;
    max-width: 100%;
    margin: 0 auto 10px auto;
  }
}
