.enquire-now-button {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%) scale(1.3); 
  background-color: white;
  color: #dca52e;
  font-size: 14px; 
  font-weight: bold; 
  border-radius: 15px 0 0 15px; 
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); 
  cursor: pointer;
  z-index: 9999999;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 15px 12px; 
}

.enquire-now-button:hover {
  background-color: #555555;
  transform: translateY(-50%) scale(1.3); 
}
